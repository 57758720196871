import React, { type FC } from "react";
import type { UseFormSetValue } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import FileUploader from "@components/FileUploader/FileUploader";
import { FormLabel, Grid } from "@mui/material";
import type { ProductPutForm } from "@src/types";
import { ACCEPT_FILES_TYPES, type ProductFieldsConfig } from "@src/types";

interface Props {
  productFieldsConfig: ProductFieldsConfig;
  setValue: UseFormSetValue<ProductPutForm>;
  formValues: ProductPutForm;
  isAssortmentFileHighlighted: boolean;
  hasUpdateProductAuthority: boolean;
  handleDeleteAdditionalPhoto: (index: number) => void;
  isDisabled: (fieldName: keyof ProductPutForm) => boolean;
}

const AssortmentTab: FC<Props> = ({
  productFieldsConfig,
  setValue,
  formValues,
  hasUpdateProductAuthority,
  isAssortmentFileHighlighted,
  handleDeleteAdditionalPhoto,
  isDisabled,
}) => {
  return (
    <Grid container item display="flex" flexDirection="row" gap={1}>
      <Grid item>
        <FormLabel>
          <FormattedMessage id="form.label.assortmentFile" />
        </FormLabel>
        <FileUploader
          {...(formValues.assortmentFile && {
            files: [formValues.assortmentFile],
          })}
          handleUploadFiles={(files) =>
            setValue("assortmentFile", files[0], { shouldDirty: true })
          }
          mandatory={productFieldsConfig.assortmentFile?.mandatory}
          accept={ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
          highlightIfRecent={isAssortmentFileHighlighted}
          limit={1}
          handleDeleteFile={() =>
            setValue("assortmentFile", undefined, { shouldDirty: true })
          }
          disabled={isDisabled("assortmentFile")}
        />
      </Grid>
      <Grid item>
        <FormLabel>
          <FormattedMessage id="form.label.additionalPhotos" />
        </FormLabel>
        <FileUploader
          files={formValues.additionalPhotos ?? []}
          handleUploadFiles={(files) => {
            const currentAdditionalPhotos = formValues?.additionalPhotos ?? [];
            setValue(
              "additionalPhotos",
              [...currentAdditionalPhotos, ...files],
              {
                shouldDirty: true,
              },
            );
          }}
          accept={ACCEPT_FILES_TYPES.ADDITIONAL_PHOTOS}
          limit={5}
          handleDeleteFile={handleDeleteAdditionalPhoto}
          disabled={isDisabled("additionalPhotos")}
          hide={!hasUpdateProductAuthority}
        />
      </Grid>
    </Grid>
  );
};

export default AssortmentTab;
