import React from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Carousel from "@components/Carousel";
import FileUploader from "@components/FileUploader/FileUploader";
import GeographicScopeButton from "@components/Inputs/GeographicScopeButton";
import { Checkbox, FormControlLabel, FormLabel, Grid } from "@mui/material";
import type { ProductHeaderForm } from "@pages/ProductPage/components/ProductPageHeader/productHeaderPage.types";
import { ACCEPT_FILES_TYPES, Authority } from "@src/types";
import { useDeletePackshotProductMutation } from "@store/api/product";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";
import type { RootState } from "@store/store";
import { GeographicScope } from "@utils/data/enums/generals.enum";

const ProductPackaging: React.FC<ProductHeaderForm> = ({
  productFieldsConfig,
  product,
  formReturn,
}) => {
  const intl = useIntl();
  const [deletePackshot] = useDeletePackshotProductMutation();

  const hasUpdateProductAuthority = useAppSelector((state: RootState) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);
  const { isCancel } = product;
  const disabled = isCancel || !isSeasonOpen || !hasUpdateProductAuthority;

  const { setValue, watch, control } = formReturn;
  const formValues = watch();

  const handleDeletePackshots = async () => {
    if (Array.isArray(formValues?.packshots)) {
      const packshotsToDelete = formValues.packshots.filter(
        (packshot) => !(packshot instanceof File),
      );
      for (let index = 0; index < packshotsToDelete.length; index++) {
        const packshot = packshotsToDelete[index];
        if (packshot) {
          try {
            await deletePackshot({
              name: packshot.name,
              productId: product.id,
            });
          } catch (error) {
            console.error(
              `Error deleting packshot ${index + 1}:`,
              packshot,
              error,
            );
          }
        }
      }
    }
  };

  return (
    <Grid item display="flex" flexDirection="row" alignItems="center">
      <Carousel
        disabled // remove the possibility to delete image and update it just by the file uploader
        images={formValues.packshots}
        mandatory={productFieldsConfig.packshots?.mandatory}
        leftIcon={
          <GeographicScopeButton
            fieldName="geographicScope"
            disabled={disabled}
            value={formValues.geographicScope}
            onChange={(scope) =>
              setValue(
                "geographicScope",
                scope === formValues.geographicScope
                  ? GeographicScope.NULL
                  : scope,
                {
                  shouldDirty: true,
                },
              )
            }
          />
        }
      />

      <Grid
        item
        display="flex"
        flexDirection="column"
        gap={0.5}
        justifyContent="center"
      >
        {hasUpdateProductAuthority && (
          <FileUploader
            disabled={disabled}
            label={intl.formatMessage({
              id: "form.label.upload.packshot",
            })}
            handleUploadFiles={async (files) => {
              await handleDeletePackshots(); // little flash when deleting the old files
              setValue("packshots", files, { shouldDirty: true });
            }}
            accept={ACCEPT_FILES_TYPES.PACKSHOTS}
            limit={1}
          />
        )}
        <Grid item>
          <FormControlLabel
            sx={{ ml: 0 }}
            control={
              <Controller
                name="newPackaging"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={Boolean(props.value)}
                    onChange={(e) => {
                      props.onChange(e.target.checked);
                      setValue("newPackaging", e.target.checked, {
                        shouldDirty: true,
                      });
                    }}
                    disabled={disabled}
                  />
                )}
              />
            }
            labelPlacement="start"
            label={
              <FormLabel>
                <FormattedMessage id="form.label.newPackaging" />
              </FormLabel>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductPackaging;
