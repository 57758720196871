import React, { type FC } from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { FormLabel, Grid, Typography } from "@mui/material";
import type { ProductGetResponse, ProductPutForm } from "@src/types";
import { FIELD_TYPES, type ProductFieldsConfig } from "@src/types";

type CommonProps = {
  product: ProductGetResponse;
  productFieldsConfig: ProductFieldsConfig;
  canUpdateProduct?: boolean;
  setValue: UseFormSetValue<ProductPutForm>;
  trigger: UseFormTrigger<ProductPutForm>;
  control: Control<ProductPutForm>;
  watch: UseFormWatch<ProductPutForm>;
  register: UseFormRegister<ProductPutForm>;
  isDisabled: (fieldName: keyof ProductPutForm) => boolean;
};

const OwnerField: FC<
  CommonProps & {
    fieldName: keyof ProductPutForm;
    product: ProductGetResponse;
  }
> = ({
  fieldName,
  product,
  register,
  watch,
  control,
  trigger,
  setValue,
  productFieldsConfig,
  isDisabled,
}) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: `form.label.${fieldName}`,
    defaultMessage: fieldName,
  });

  return (
    <Grid item display="flex" flex={1}>
      {!isDisabled(fieldName) ? (
        <GenericFormField<ProductPutForm>
          field={{
            fieldName,
            fieldType: FIELD_TYPES.INPUT_TEXT,
            mandatory: productFieldsConfig[fieldName]?.mandatory,
            muiVariant: "standard",
            size: "small",
          }}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      ) : (
        <Grid item display="flex" flexDirection="column">
          <FormLabel>{label}</FormLabel>
          <Typography>
            {fieldName in product ? (product[fieldName] as string) : ""}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const OwnersTab: FC<CommonProps> = (props) => {
  const ownersFields: (keyof ProductPutForm)[] = [
    "mopOwner",
    "digitalOwner",
    "productOwner",
    "imageProductOwner",
    "imageModelShotOwner",
  ];

  return (
    <Grid container display="flex" flexDirection="row" gap={2}>
      {ownersFields.map((fieldName) => (
        <OwnerField {...props} key={fieldName} fieldName={fieldName} />
      ))}
    </Grid>
  );
};

export default OwnersTab;
