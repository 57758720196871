import * as XLSX from "xlsx";

export const getDataFile = async (
  file: File,
  setData: (data: string[][] | null) => void,
) => {
  if (file) {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      }) as string[][];

      const cleanedData = jsonData.filter((row) =>
        // to prevent TypeError: cell.trim is not a function error in front
        row.some((cell) => typeof cell === "string" && cell.trim() !== ""),
      );
      setData(cleanedData); // Store all data
    } catch (err) {
      console.error("Error during file treatment:", err);
    }
  }
};
