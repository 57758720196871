import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import ModalLayout from "@components/Modals/ModalLayout";
import { TuneOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import useFilters from "@pages/SeasonPage/hooks/useFilters";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type {
  ProductFilter,
  ProductFilterValues,
  ProductGroupedByLevel,
  SelectOptionType,
} from "@src/types";
import {
  BOOLEAN_MODE,
  FIELD_TYPES,
  FieldLayout,
  MODAL_ACTION_TYPES,
} from "@src/types";
import { useGetCategoryWithAxisQuery } from "@store/api/categories";
import { useLazyGetSubCategoriesWithCategoryIdQuery } from "@store/api/subCategories";
import { useAppSelector } from "@store/hooks";
import { selectedSeason } from "@store/season/season.selector";
import {
  GeographicScopeOption,
  LevelOfActivationOption,
  StatusOption,
} from "@utils/constants.utils";
import { countActiveFilters } from "@utils/filter";

export interface SeasonPageProductFiltersProps {
  products?: ProductGroupedByLevel[];
  setFiltersProducts: (
    filteredProducts: ProductGroupedByLevel[] | null,
  ) => void;
}

const productFilters = (
  categories: SelectOptionType[],
  subCategories: SelectOptionType[],
  filterValues: ProductFilterValues,
): ProductFilter[] => [
  {
    fieldLabel: filterValues?.name
      ? "product.filters.search"
      : "product.filters.search-products",
    fieldName: "name",
    fieldType: FIELD_TYPES.INPUT_TEXT,
    value: filterValues?.name,
  },
  {
    fieldName: "category",
    fieldType: FIELD_TYPES.SELECT,
    options: categories,
    value: filterValues?.category,
  },
  {
    fieldName: "subCategory",
    fieldType: FIELD_TYPES.SELECT,
    options: subCategories,
    value: filterValues?.subCategory,
  },
  {
    fieldName: "level",
    fieldType: FIELD_TYPES.SELECT,
    options: LevelOfActivationOption,
    value: filterValues?.level,
  },
  {
    fieldName: "status",
    fieldType: FIELD_TYPES.SELECT,
    options: StatusOption,
    value: filterValues?.status,
  },
  {
    fieldName: "geographicScope",
    fieldType: FIELD_TYPES.MULTI_SELECT,
    options: GeographicScopeOption.map(
      (geographicScope) => geographicScope.value,
    ),
    value: filterValues?.geographicScope,
  },
  {},
  {
    fieldName: "nbModifiedAssets",
    fieldType: FIELD_TYPES.BOOLEAN,
    checked: filterValues?.nbModifiedAssets,
    mode: BOOLEAN_MODE.TOGGLE,
  },
  {
    fieldName: "newPackaging",
    fieldType: FIELD_TYPES.BOOLEAN,
    checked: filterValues?.newPackaging,
    mode: BOOLEAN_MODE.TOGGLE,
  },
];

const ProductFilters: React.FC<SeasonPageProductFiltersProps> = ({
  products,
  setFiltersProducts,
}) => {
  const intl = useIntl();
  const selectedSeasonState = useAppSelector(selectedSeason);
  const { handleOpenPopup, isPopupOpen, handleClosePopup } = usePopupHandler();

  const { data: categories = [] } = useGetCategoryWithAxisQuery({
    axisId: selectedSeasonState?.axisId,
  });

  const [getSubCategories, { data: subCategories }] =
    useLazyGetSubCategoriesWithCategoryIdQuery({
      selectFromResult: ({ data }) => ({
        data:
          data?.map((subCategory) => ({
            value: subCategory.name,
            label: subCategory.name,
          })) || [],
      }),
    });

  const { watch, reset, register, setValue, control, trigger } =
    useForm<ProductFilter>({
      defaultValues: {
        name: "",
        category: "",
        subCategory: "",
        level: "",
        status: "",
        geographicScope: [],
      },
    });

  const filterValues = watch();
  const { category } = filterValues;

  useEffect(() => {
    (async function fetch() {
      const categoryId =
        category && categories?.find((cat) => cat.name === category)?.id;
      if (categoryId && categories) {
        await getSubCategories({
          category: categoryId,
        });
      }
    })();
    setValue("subCategory", undefined);
  }, [category]);

  const filters = useMemo(
    () =>
      productFilters(
        categories?.map((cat) => ({
          value: cat.name,
          label: cat.name,
        })),
        subCategories,
        filterValues,
      ),
    [filterValues, categories],
  );

  const { countProduct } = useFilters(products, filters, setFiltersProducts);

  const activeFiltersCount = useMemo(
    () => countActiveFilters(filters),
    [filters],
  );

  const handleReset = () => {
    const searchedProduct = filterValues.name;
    reset();
    setValue("name", searchedProduct);
  };

  return (
    <form style={{ width: "100%" }}>
      <Grid
        container
        gap={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Grid item>
          <GenericFormField
            field={{
              ...filters[0],
              layout: FieldLayout.ONLY_PLACEHOLDER,
            }}
            setValue={setValue}
            register={register}
            control={control}
            trigger={trigger}
            watch={watch}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleOpenPopup(MODAL_ACTION_TYPES.NONE)}>
            <Badge badgeContent={activeFiltersCount} color="primary">
              <TuneOutlined />
            </Badge>
          </IconButton>
        </Grid>
        <Grid item>
          {activeFiltersCount > 0 && (
            <Typography variant="caption">
              (
              <FormattedMessage
                id="product.count.nb-results"
                values={{ count: countProduct }}
              />
              )
            </Typography>
          )}
        </Grid>
      </Grid>
      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage({ id: "product.filters.modal.title" })}
      >
        <>
          <Typography>
            <FormattedMessage
              id="product.count.nb-results"
              values={{ count: countProduct }}
            />
          </Typography>
          <Grid container spacing={2}>
            {filters?.slice(1)?.map((filter) => (
              <Grid
                item
                key={filter.fieldName}
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
              >
                <GenericFormField
                  field={filter}
                  setValue={setValue}
                  register={register}
                  control={control}
                  trigger={trigger}
                  watch={watch}
                />
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="space-between" flex={1}>
            <Button variant="outlined" onClick={handleReset}>
              {intl.formatMessage({ id: "product.filters.reset" })}
            </Button>
            <Button variant="contained" onClick={handleClosePopup}>
              {intl.formatMessage({ id: "common.confirm" })}
            </Button>
          </Box>
        </>
      </ModalLayout>
    </form>
  );
};

export default ProductFilters;
