import React, { useState } from "react";
import type { FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import GeographicScopeComponent from "@components/DataDisplay/GeographicScope";
import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { GeographicScope } from "@utils/data/enums/generals.enum";

interface Props<T extends FieldValues> {
  fieldName: keyof T;
  value: GeographicScope;
  onChange: (scope: GeographicScope) => void;
  disabled?: boolean;
}

const GeographicScopeButton = <T extends FieldValues>({
  fieldName,
  value,
  onChange,
  disabled,
}: Props<T>) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSelectScope = (scope: GeographicScope) => {
    onChange(scope);
    handleMenuClose(); // Close menu after selection
  };

  if (disabled && value === GeographicScope.NULL) {
    return <></>;
  }

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        sx={{
          ...(value === GeographicScope.NULL && {
            border: "2px solid",
            borderColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.light",
              borderColor: "primary.dark",
            },
          }),
          borderRadius: "100%",
          p: 0.75,
          backgroundColor: "background.paper",
          "&.Mui-disabled": {
            borderColor: "grey.400",
            backgroundColor: "grey.100",
          },
        }}
      >
        {value === GeographicScope.NULL ? (
          <AddOutlined sx={{ color: "primary.main" }} />
        ) : (
          <GeographicScopeComponent
            disabled={disabled}
            scope={value}
            selected
          />
        )}
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          p: 0,
        }}
        MenuListProps={{
          sx: { p: 0 },
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              py: 1,
              px: 2,
            }}
          >
            <FormLabel>
              <FormattedMessage
                id={`form.label.${fieldName as string}`}
                defaultMessage={fieldName as string}
              />
            </FormLabel>
          </Typography>
          {Object.values(GeographicScope).map((scope) => (
            <MenuItem
              key={scope}
              onClick={() => handleSelectScope(scope)}
              disabled={disabled}
              sx={{
                px: 2,
                py: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <GeographicScopeComponent
                scope={scope}
                selected={scope === value}
                disabled={disabled}
                showEmpty
              />
              <Typography variant="body2" sx={{ ml: 1 }}>
                <FormattedMessage id={`form.value.${scope.toLowerCase()}`} />
              </Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  );
};

export default GeographicScopeButton;
