import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { type FieldPath, type FieldValues, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import Carousel from "@components/Carousel/Carousel";
import Dot from "@components/Dot/Dot";
import Dropzone from "@components/Dropzone";
import IconEqual from "@components/Icon/IconEqual";
import IconMultiplication from "@components/Icon/IconMultiplication";
import AssetFormField from "@components/Inputs/AssetFormField";
import TagsSelector from "@components/Inputs/TagsSelector";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import ProductActions from "@components/ProductActions";
import { CancelScheduleSendOutlined, ExpandMore } from "@mui/icons-material";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  ButtonBase,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useAssetFieldDisabled from "@src/hooks/useAssetFieldDisabled";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { AssetFileValueResponse } from "@src/types";
import {
  ACCEPT_FILES_TYPES,
  AssetContentFieldStatus,
  type AssetFileValueRequest,
  type AssetFormKeys,
  AssetTag,
  type AssetValue,
  Authority,
  type ContentKeys,
  MODAL_ACTION_TYPES,
} from "@src/types";
import {
  useDeleteBenchVisualAssetMutation,
  usePutAssetMutation,
} from "@store/api/asset";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectProduct } from "@store/product/product.selector";
import {
  selectAreWeBeforeNoDefDate,
  selectIsSeasonOpen,
  selectedSeason,
} from "@store/season/season.selector";
import { colors } from "@theme/theme";
import { DEBOUNCE_DELAY, SARDINES_AXIS_ID } from "@utils/constants.utils";
import { ItemType } from "@utils/data/enums/generals.enum";
import { getDirtyValues, getOnlyValueFromAsset } from "@utils/fonctions.utils";

import dayjs from "dayjs";
import type { Asset, AssetForm } from "types/api/asset";
import { useDebouncedCallback } from "use-debounce";

interface IProductsElementListProps {
  asset: Asset;
  productIsCancel: boolean;
}

const AssetsElementList: FC<IProductsElementListProps> = ({
  asset,
  productIsCancel,
}) => {
  const intl = useIntl();
  const season = useAppSelector(selectedSeason);
  const { id: productId } = useAppSelector(selectProduct) || {};
  const theme = useTheme();

  const hasUpdateAssetAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_ASSET),
  );
  const useIsDisabled = useAssetFieldDisabled();
  const isBenchVisualsHighlighted = !useAppSelector(selectAreWeBeforeNoDefDate);

  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);

  const hasUpdateDoneByImageTeamAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_ASSET_IMAGE_TEAM),
  );

  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);
  const [useStatus, setUseStatus] = useState<boolean>(false);

  const [deleteBenchVisuals] = useDeleteBenchVisualAssetMutation();
  const [putAsset] = usePutAssetMutation();

  const {
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
    defaultValues,
  } = usePopupHandler();

  const {
    register,
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm<AssetForm>({ mode: "onBlur" });

  const formValues = watch();
  const { bench_visuals } = formValues;

  // 2. backend update request
  const onSubmit = () => {
    if (productId) {
      const fieldsToUpdate = getDirtyValues(
        dirtyFields,
        formValues,
        getOnlyValueFromAsset(asset),
        useStatus,
      );

      // do not update an asset field if value is mandatory AND empty
      for (const key in fieldsToUpdate) {
        const isMandatory = asset.content[key as ContentKeys].mandatory;
        const isEmpty = Array.isArray(fieldsToUpdate[key as AssetFormKeys])
          ? !(fieldsToUpdate[key as AssetFormKeys] as unknown as AssetValue[])
              .length
          : (fieldsToUpdate[key as AssetFormKeys] as AssetValue).value === "";
        if (isMandatory && isEmpty) {
          delete fieldsToUpdate[key as AssetFormKeys];
        }
      }

      const benchVisuals = fieldsToUpdate?.bench_visuals as
        | (AssetFileValueRequest | AssetFileValueResponse)[]
        | undefined;
      const isNewFileToBenchVisuals = benchVisuals?.some(
        (item): item is AssetFileValueRequest => item.value instanceof File,
      );
      const filteredBenchVisuals =
        benchVisuals
          ?.filter(
            (item): item is AssetFileValueRequest =>
              isNewFileToBenchVisuals === item.value instanceof File,
          )
          .map(({ value, status }) => ({ value, status })) ?? [];

      if (
        Object.keys(fieldsToUpdate).length > 0 ||
        (isNewFileToBenchVisuals && filteredBenchVisuals.length > 0)
      ) {
        putAsset({
          assetSimpleField: fieldsToUpdate,
          bench_visuals: filteredBenchVisuals,
          id: asset.id,
        });
      }
    }
  };

  const onSubmitDebounced = useDebouncedCallback(onSubmit, DEBOUNCE_DELAY);

  // 1. auto submit data when form data changes
  useEffect(() => {
    const dirtyFieldNames = Object.keys(dirtyFields) as (keyof AssetForm)[];

    // prevent double submit when checking differences between back response et form values
    const assetValue = getOnlyValueFromAsset(asset);
    const isAssetChanged = dirtyFieldNames.some(
      (dirtyField) =>
        JSON.stringify(formValues[dirtyField]) !==
        JSON.stringify(assetValue[dirtyField]),
    );
    if (isAssetChanged && isDirty && dirtyFieldNames.length > 0) {
      onSubmitDebounced();
    }
  }, [JSON.stringify(formValues)]);

  // 3. rebase form values on backend response
  useEffect(() => {
    reset(getOnlyValueFromAsset(asset));
  }, [asset]);

  useEffect(() => {
    if (season) {
      // actual deadline no def day is still considered as before due date
      setUseStatus(
        dayjs().subtract(1, "day").isSameOrAfter(season.deadlineNoDef),
      );
    }
  }, [season]);

  const handleUpdateArrayValue = (
    fieldName: FieldPath<AssetForm>,
    value: AssetFileValueRequest[],
  ) => {
    const currentFieldName = watch(fieldName);
    if (Array.isArray(currentFieldName)) {
      setValue(fieldName, [...(currentFieldName as Array<any>), ...value], {
        shouldDirty: true,
      });
    } else {
      setValue(fieldName, value, { shouldDirty: true });
    }
  };

  const handleDeleteBenchVisuals = (index: number) => {
    if (bench_visuals && productId) {
      deleteBenchVisuals({
        file: bench_visuals.value[index]?.value.name,
        id: asset.id,
      });
    }
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const [disableRipple, setDisableRipple] = useState(false);

  const handleChipClick = (
    e: React.MouseEvent,
    actionType: MODAL_ACTION_TYPES,
    data: FieldValues,
  ) => {
    e.stopPropagation();
    setDisableRipple(true); // Temparary deactivate ripple effect on parent component
    handleOpenPopup(actionType, data);
    setTimeout(() => setDisableRipple(false), 0); // reactivate just after click
  };

  //TODO - fetch this value from back-end instead of computing it front-side
  const isTopPriorityAsset = asset.content.new_tag.value?.some(
      (tag) => tag.value === AssetTag.TOP_PRIORITY_ASSET,
    ),
    isOtherAxis = season?.axisId === SARDINES_AXIS_ID;

  const isCancel = asset.isCancel || productIsCancel;

  const _renderTab = () => {
    if (tabValue === 0) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.ppage}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.digital_animation}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 1) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.permanent_instore}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.temporary_instore}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 2) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.media_regional_context}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.media_traditional_tv_dooh_ooh}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              field={asset.content.media_digital}
              register={register}
              useStatus={useStatus}
              disabled={isCancel}
            />
          </Grid>
        </Grid>
      );
    }
  };

  if (!Object.keys(formValues).length) {
    return <></>;
  }

  return (
    <>
      <Paper
        sx={{
          p: 1,
          m: 1,
          transition: "all 0.3s ease",
          backgroundColor:
            (!isSeasonOpen || isCancel) && !isOpenCollapse
              ? theme.palette.action.disabledBackground
              : "auto",
        }}
      >
        <form>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Grid item display="flex" flexGrow={1}>
              <ButtonBase
                onClick={() => setIsOpenCollapse((prevState) => !prevState)}
                sx={{ width: "100%", justifyContent: "flex-start" }}
                disableRipple={disableRipple}
              >
                <Grid container item gap={2} width="auto" alignItems="center">
                  <Grid item>
                    <IconButton
                      disableTouchRipple
                      disableRipple
                      sx={{
                        transition: "transform 0.3s ease",
                        transform: isOpenCollapse
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <Typography
                      fontWeight="fontWeightBold"
                      color={isCancel ? theme.palette.action.disabled : "auto"}
                    >
                      {asset.type}
                    </Typography>
                  </Grid>
                  {asset.modified && (
                    <Box sx={{ width: 15, height: 15 }}>
                      <Dot color={colors.warning} />
                    </Box>
                  )}
                  {asset.isCancel && (
                    <Tooltip title={asset.whyIsCancel}>
                      <Chip
                        color="error"
                        variant="outlined"
                        icon={<CancelOutlinedIcon color="error" />}
                        label={intl.formatMessage({
                          id: "asset.status.cancelled",
                        })}
                      />
                    </Tooltip>
                  )}
                  {asset.isWaitingForApproval && (
                    <Tooltip title={asset.whyIsWaitingForApproval}>
                      <Chip
                        {...(hasUpdateAssetAuthority &&
                          isSeasonOpen && {
                            onClick: (e: React.MouseEvent<HTMLElement>) =>
                              handleChipClick(
                                e,
                                MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL,
                                {
                                  whyIsWaitingForApproval:
                                    asset.whyIsWaitingForApproval,
                                },
                              ),
                          })}
                        icon={<AutorenewOutlinedIcon />}
                        label={intl.formatMessage({
                          id: "asset.status.waiting-for-approval",
                        })}
                      />
                    </Tooltip>
                  )}
                  {!asset.isDelivered && (
                    <Tooltip title={asset.whyNotDelivered}>
                      <Chip
                        {...(hasUpdateDoneByImageTeamAuthority && {
                          onClick: (e: React.MouseEvent<HTMLElement>) =>
                            handleChipClick(
                              e,
                              MODAL_ACTION_TYPES.UNDELIVER_ASSET,
                              {
                                whyNotDelivered: asset.whyNotDelivered,
                              },
                            ),
                        })}
                        icon={<CancelScheduleSendOutlined />}
                        label={intl.formatMessage({
                          id: "asset.status.not-delivered",
                        })}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ProductActions
                id={asset.id}
                isAsset
                disabled={productIsCancel}
                actions={[
                  {
                    action: asset.isWaitingForApproval
                      ? MODAL_ACTION_TYPES.UNSET_WAITING_FOR_APPROVAL
                      : MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL,
                    defaultValues: {
                      whyIsWaitingForApproval: asset.whyIsWaitingForApproval,
                    },
                  },
                  {
                    action: asset.isCancel
                      ? MODAL_ACTION_TYPES.UNDO_CANCEL
                      : MODAL_ACTION_TYPES.CANCEL,
                    defaultValues: {
                      whyIsCancel: asset.whyIsCancel,
                    },
                  },
                  { action: MODAL_ACTION_TYPES.DELETE_ASSET },
                ]}
              />
            </Grid>
          </Grid>

          <Grid mb={1} ml={2}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" gap={2}>
                {[
                  asset.content.digital,
                  asset.content.infographic,
                  asset.content.print,
                  asset.content.media,
                ].map((field) => (
                  <AssetFormField
                    key={field.name}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    trigger={trigger}
                    field={field}
                    register={register}
                    useStatus={useStatus}
                    disabled={isCancel}
                  />
                ))}
              </Stack>

              {dayjs().isAfter(season?.deadlineDef) && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={asset.isDelivered}
                        disabled={
                          !hasUpdateDoneByImageTeamAuthority || isCancel
                        }
                        onClick={() => {
                          handleOpenPopup(
                            asset.isDelivered
                              ? MODAL_ACTION_TYPES.UNDELIVER_ASSET
                              : MODAL_ACTION_TYPES.DELIVER_ASSET,
                            { whyNotDelivered: asset.whyNotDelivered },
                          );
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "asset.status.done-by-image-team",
                    })}
                  />
                </Grid>
              )}
            </Box>

            <TagsSelector
              options={asset.content.new_tag.possibleValues}
              formValues={formValues}
              setValue={setValue}
              disabled={useIsDisabled(asset.content.new_tag) || isCancel}
            />
          </Grid>

          <Box mx={2}>
            <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
              <Grid container rowGap={2} mt={2}>
                {(isTopPriorityAsset || isOtherAxis) && (
                  <Grid
                    container
                    item
                    gap={2}
                    display="flex"
                    flexDirection="row"
                  >
                    <Grid item minWidth={280}>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.digital_def_delivery_deadline}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                    <Grid item minWidth={280}>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.print_def_asset_delivery_deadline}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={5} gap={2} display="flex" flexDirection="column">
                  <Grid item>
                    <AssetFormField
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      trigger={trigger}
                      field={asset.content.geographic_scope}
                      register={register}
                      useStatus={useStatus}
                      disabled={isCancel}
                    />
                  </Grid>
                  <Grid item display="flex" flexDirection="row" gap={4}>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.skus}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.name_of_shades}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.nb_of_carnations}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                    <Grid item>
                      <IconMultiplication fontSize="small" />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.nb_of_shades_to_shoot}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                    <Grid item>
                      <IconEqual fontSize="large" />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        field={asset.content.total_nb_of_assets}
                        register={register}
                        useStatus={useStatus}
                        disabled={isCancel}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={7} gap={2} display="flex" flexDirection="row">
                  <Divider orientation="vertical" sx={{ pl: 2 }} />

                  <Grid
                    item
                    sx={{ flexWrap: { md: "wrap", xl: "nowrap" } }}
                    gap={2}
                    display="flex"
                    flexDirection="row"
                  >
                    <Carousel
                      title={intl.formatMessage({
                        id: "form.label.bench_visuals.value",
                      })}
                      nbElementsPerView={3}
                      handleClickDelete={(deletedIndex) => {
                        const updatedBenchVisuals = [...bench_visuals.value];
                        const currentStatus =
                          updatedBenchVisuals[deletedIndex].status;

                        const isCurrentlyDeleted =
                          currentStatus === AssetContentFieldStatus.DELETED;
                        updatedBenchVisuals[deletedIndex] = {
                          ...updatedBenchVisuals[deletedIndex],
                          status: isCurrentlyDeleted
                            ? AssetContentFieldStatus.CREATED
                            : AssetContentFieldStatus.DELETED,
                        };

                        if (!isCurrentlyDeleted)
                          handleDeleteBenchVisuals(deletedIndex);

                        setValue("bench_visuals.value", updatedBenchVisuals, {
                          shouldDirty: isCurrentlyDeleted,
                        });
                      }}
                      highlightImages={isBenchVisualsHighlighted}
                      images={bench_visuals.value}
                      disabled={
                        useIsDisabled(asset.content.bench_visuals) || isCancel
                      }
                    />
                    <Dropzone
                      handleUploadFile={(files) =>
                        handleUpdateArrayValue(
                          "bench_visuals.value",
                          files.map((file) => ({
                            value: file,
                            status: AssetContentFieldStatus.NULL,
                          })),
                        )
                      }
                      fieldLabel={intl.formatMessage({
                        id: "form.label.bench_visuals",
                      })}
                      limit={asset.content.bench_visuals.maxLength}
                      accept={ACCEPT_FILES_TYPES.BENCH_VISUALS}
                      disabled={!isSeasonOpen || isCancel}
                      hide={useIsDisabled(asset.content.bench_visuals)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                gap={2}
                width="100%"
              >
                <Grid item width="100%">
                  <AssetFormField
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    trigger={trigger}
                    field={asset.content.comment}
                    register={register}
                    useStatus={useStatus}
                    disabled={isCancel}
                  />
                </Grid>
                <Grid item width="100%">
                  <AssetFormField
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    trigger={trigger}
                    field={asset.content.comment_from_marketing_product}
                    register={register}
                    useStatus={useStatus}
                    disabled={isCancel}
                  />
                </Grid>
              </Grid>

              <Tabs value={tabValue} onChange={handleTabChange} sx={{ mt: 1 }}>
                {[
                  intl.formatMessage({ id: "asset.detail.tab.digital" }),
                  intl.formatMessage({ id: "asset.detail.tab.print" }),
                  intl.formatMessage({ id: "asset.detail.tab.media" }),
                ].map((tab) => (
                  <Tab key={tab} label={tab} />
                ))}
              </Tabs>
              <Box mt={2}>{_renderTab()}</Box>
            </Collapse>
          </Box>
        </form>
      </Paper>
      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage(
          {
            id: `common.modal.generic-modal.title.${actionType}`,
          },
          { itemType: ItemType.ASSET },
        )}
      >
        <GenericProductModal
          id={asset.id}
          actionType={actionType}
          onClose={handleClosePopup}
          isAsset
          defaultValues={defaultValues}
        />
      </ModalLayout>
    </>
  );
};

export default AssetsElementList;
