import React, { type FC } from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import GenericFormField from "@components/Inputs/GenericFormField";
import { ArrowBackOutlined } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import ProductDuplicateInfo from "@pages/ProductPage/components/ProductPageHeader/components/ProductDuplicateInfo";
import type {
  ProductGetResponse,
  ProductPutForm,
  SeasonsGlobalInformations,
} from "@src/types";
import {
  Authority,
  FIELD_TYPES,
  FieldLayout,
  type ProductFieldsConfig,
} from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";
import type { RootState } from "@store/store";

interface Props {
  productFieldsConfig: ProductFieldsConfig;
  product: ProductGetResponse;
  displayBenefit?: boolean;
  season?: SeasonsGlobalInformations;
  setValue: UseFormSetValue<ProductPutForm>;
  trigger: UseFormTrigger<ProductPutForm>;
  register: UseFormRegister<ProductPutForm>;
  control: Control<ProductPutForm>;
  watch: UseFormWatch<ProductPutForm>;
}

const HighlightedFields: FC<Props> = ({
  productFieldsConfig,
  product,
  register,
  displayBenefit,
  season,
  setValue,
  trigger,
  control,
  watch,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const hasUpdateProductAuthority = useAppSelector((state: RootState) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);
  const { duplicateOf, duplicatedIn, name, isCancel, whyIsCancel } = product;
  const disabled = isCancel || !isSeasonOpen || !hasUpdateProductAuthority;

  const showDuplicate = duplicateOf || !!duplicatedIn?.length;

  const onClickNavigate = (seasonCleanName?: string) => {
    if (seasonCleanName) {
      const redirectWithParams = generatePath("/season/:seasonCleanName", {
        seasonCleanName,
      });
      navigate(redirectWithParams);
    }
  };

  return (
    <Grid
      item
      borderRadius="8px"
      sx={{ backgroundColor: "background.default" }}
      px={2}
      pt={1}
      pb={2}
      xs={4}
      gap={0.5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {season && (
        <Grid item>
          <Button
            startIcon={<ArrowBackOutlined />}
            onClick={() => onClickNavigate(season.cleanName)}
          >
            {season.name}
          </Button>
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={0.5}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={showDuplicate ? 11 : 12}>
            {!disabled ? (
              <GenericFormField<ProductPutForm>
                field={{
                  disabled,
                  fieldName: "name",
                  fieldType: FIELD_TYPES.INPUT_TEXT,
                  maxRows: 2,
                  label: "",
                  mandatory: productFieldsConfig.name.mandatory,
                  muiVariant: "standard",
                  multiline: true,
                  inputProps: {
                    style: {
                      fontSize: 20,
                      fontWeight: "fontWeightBold",
                      textTransform: "uppercase",
                    },
                  },
                }}
                setValue={setValue}
                trigger={trigger}
                register={register}
                control={control}
                watch={watch}
              />
            ) : (
              <Typography variant="h5" fontWeight="fontWeightBold">
                {name.toUpperCase()}
              </Typography>
            )}
          </Grid>

          {showDuplicate && (
            <Grid item xs={1}>
              <ProductDuplicateInfo
                product={product}
                onClickNavigate={onClickNavigate}
              />
            </Grid>
          )}
        </Grid>
        {isCancel && (
          <Grid item>
            <Tooltip title={whyIsCancel}>
              <Chip
                color="error"
                variant="outlined"
                sx={{ ml: 1 }}
                icon={<CancelOutlinedIcon color="error" />}
                label={intl.formatMessage({ id: "product.status.cancelled" })}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <GenericFormField<ProductPutForm>
          field={{
            fieldName: "numberOfSkus",
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            mandatory: productFieldsConfig.numberOfSkus.mandatory,
            muiVariant: "standard",
            disabled,
            sx: { width: 40, textAlign: "right" },
            inputProps: {
              sx: {
                textAlign: "right",
              },
            },
            layout: FieldLayout.DISSOCIATED_LABEL,
          }}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <GenericFormField<ProductPutForm>
          field={{
            fieldName: "sellOutAmbition",
            fieldType: FIELD_TYPES.INPUT_TEXT,
            mandatory: productFieldsConfig.sellOutAmbition.mandatory,
            muiVariant: "standard",
            disabled,
            sx: { width: 80 },
            inputProps: {
              sx: {
                textAlign: "right",
              },
            },
            layout: FieldLayout.DISSOCIATED_LABEL,
          }}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      </Grid>
      {displayBenefit && (
        <Grid
          item
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <GenericFormField<ProductPutForm>
            field={{
              fieldName: "benefit",
              fieldType: FIELD_TYPES.INPUT_TEXT,
              mandatory: productFieldsConfig.benefit?.mandatory,
              muiVariant: "standard",
              disabled,
              sx: { width: 80 },
              inputProps: {
                sx: {
                  textAlign: "right",
                },
              },
              layout: FieldLayout.DISSOCIATED_LABEL,
            }}
            setValue={setValue}
            trigger={trigger}
            register={register}
            control={control}
            watch={watch}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default HighlightedFields;
