import React, {
  type FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import {
  Button,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type {
  AssetPostForm,
  AssetPostRequest,
  FieldConfig,
  SeparatorConfig,
} from "@src/types";
import { ACCEPT_FILES_TYPES, FIELD_TYPES } from "@src/types";
import { usePostAssetMutation } from "@store/api/asset";
import { useGetAvailableNewAssetQuery } from "@store/api/product";
import { useAppSelector } from "@store/hooks";
import { selectedSeason } from "@store/season/season.selector";
import { colors } from "@theme/theme";
import {
  ACCESSORIES_AXIS_ID,
  CARE_AND_BTG_AXIS_ID,
  HOLIDAY_AXIS_ID,
  MAKE_UP_AXIS_ID,
  SARDINES_AXIS_ID,
  initAsset,
  possibleValues,
} from "@utils/constants.utils";
import type { CommonAssetType } from "@utils/enum.utils";

interface Props {
  productId: number;
  onClose: () => void;
  scrollToTop?: () => void;
}

const AddAssetModal: FC<Props> = ({ productId, onClose, scrollToTop }) => {
  const [postAsset, { isLoading, isSuccess }] = usePostAssetMutation();
  const intl = useIntl();

  const season = useAppSelector(selectedSeason);
  const isOtherAxis = season?.axisId === SARDINES_AXIS_ID;
  const possibleValuesPermanentInStore = () => {
    switch (season?.axisId) {
      case MAKE_UP_AXIS_ID:
      case ACCESSORIES_AXIS_ID:
      case SARDINES_AXIS_ID:
        return possibleValues.permanent_instore1;
      case CARE_AND_BTG_AXIS_ID:
        return possibleValues.permanent_instore2;
      default:
        return [];
    }
  };
  const possibleValuesTemporaryInStore = () => {
    switch (season?.axisId) {
      case MAKE_UP_AXIS_ID:
      case ACCESSORIES_AXIS_ID:
      case CARE_AND_BTG_AXIS_ID:
      case SARDINES_AXIS_ID:
        return possibleValues.temporary_instore1;
      case HOLIDAY_AXIS_ID:
        return possibleValues.temporary_instore2;
      default:
        return [];
    }
  };
  const [typeOption, setTypeOption] = useState<CommonAssetType[]>([]);
  const { data: availableAsset } = useGetAvailableNewAssetQuery({
    id: productId,
  });
  const {
    watch,
    control,
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
    setError,
    clearErrors,
  } = useForm<AssetPostForm>({
    mode: "onBlur",
    defaultValues: { ...initAsset, productId },
  });

  const formValues = watch();
  const formatFields = [
    formValues.ppage,
    formValues.digital_animation,
    formValues.media_traditional_tv_dooh_ooh,
    formValues.media_digital,
    formValues.permanent_instore,
    formValues.temporary_instore,
  ];

  // to not display custom errors before first submit
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (availableAsset) {
      setTypeOption(availableAsset);
    }
  }, [availableAsset]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = async () => {
    setSubmitted(true);
    const { type, productId, ...form } = formValues;
    const isAtLeastOneFormatIsFilled = validateAtLeastOneFormatIsFilled(true);
    await trigger();
    if (isAtLeastOneFormatIsFilled && isValid) {
      const assetPost: AssetPostRequest = {
        type,
        productId,
        content: form,
      };
      postAsset(assetPost);
    }
  };

  // handle global error from formatFields
  const validateAtLeastOneFormatIsFilled = (
    submittedBefore?: boolean,
  ): boolean => {
    submittedBefore =
      submittedBefore === undefined ? submitted : submittedBefore;
    const isAtLeastOneFieldFilled = formatFields.some(
      (formatField) => formatField?.length,
    );
    if (submittedBefore && !isAtLeastOneFieldFilled) {
      setError("globalError", {
        type: "manual",
        message: intl.formatMessage({ id: "form.error.atLeastOneFormat" }),
      });
      return false;
    } else {
      clearErrors("globalError");
      return true;
    }
  };

  useEffect(() => {
    validateAtLeastOneFormatIsFilled();
  }, [...formatFields, submitted]);

  const [currentStep, setCurrentStep] = useState(0);
  const formConfig: Record<number, FieldConfig<AssetPostForm>[]> = {
    0: [
      {
        fieldName: "type",
        fieldType: FIELD_TYPES.SELECT_SEARCH,
        options: typeOption.map((opt) => ({ value: opt, label: opt })),
        mandatory: true,
      },
      ...(isOtherAxis
        ? [
            {
              fieldName: "digital_def_delivery_deadline.value",
              fieldType: FIELD_TYPES.DATE,
            } as FieldConfig<AssetPostForm>,
            {
              fieldName: "print_def_asset_delivery_deadline.value",
              fieldType: FIELD_TYPES.DATE,
            } as FieldConfig<AssetPostForm>,
          ]
        : []),
      {
        fieldName: "comment.value",
        fieldType: FIELD_TYPES.RICH_TEXT,
      },
      {
        fieldName: "nb_of_shades_to_shoot.value",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "nb_of_carnations.value",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "total_nb_of_assets.value",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "bench_visuals",
        fieldType: FIELD_TYPES.DROP_ZONE_CAROUSEL,
        images: formValues.bench_visuals,
        accept: ACCEPT_FILES_TYPES.BENCH_VISUALS,
        nbElementsPerView: 3,
        maxLength: 30,
      },
      {
        fieldName: "skus.value",
        fieldType: FIELD_TYPES.INPUT_TEXT,
      },
      {
        fieldName: "name_of_shades.value",
        fieldType: FIELD_TYPES.INPUT_TEXT,
      },
    ],
    1: [
      {
        fieldName: "geographic_scope",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.geographic_scope,
        mandatory: true,
      },
      {
        fieldName: "ppage",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.ppage,
      },
      {
        fieldName: "digital_animation",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.digital_animation,
      },
      {
        fieldName: "permanent_instore",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValuesPermanentInStore(),
      },
      {
        fieldName: "temporary_instore",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValuesTemporaryInStore(),
      },
      {
        fieldName: "media_regional_context.value",
        fieldType: FIELD_TYPES.INPUT_TEXT,
      },
      {
        fieldName: "media_traditional_tv_dooh_ooh",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.media_traditional_tv_dooh_ooh,
      },
      {
        fieldName: "media_digital",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.media_digital,
      },
    ],
  };

  const separatorConfig: SeparatorConfig[] = [
    {
      firstFieldNameInCategory: "ppage",
      label: intl.formatMessage({ id: "form.label.digital.value" }),
    },
    {
      firstFieldNameInCategory: "media_regional_context.value",
      label: intl.formatMessage({ id: "form.label.media.value" }),
    },
    {
      firstFieldNameInCategory: "permanent_instore",
      label: intl.formatMessage({ id: "form.label.print.value" }),
    },
  ];

  const getSeparatorConfig: (
    field: FieldConfig<AssetPostForm>,
  ) => SeparatorConfig | undefined = useCallback(
    (field: FieldConfig<AssetPostForm>) => {
      return separatorConfig.find(
        (config) => config.firstFieldNameInCategory === field.fieldName,
      );
    },
    [separatorConfig],
  );

  useEffect(() => {
    if (scrollToTop) scrollToTop();
  }, [currentStep]);

  const handleClickPrev = async () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleClickNext = async () => {
    await trigger(formConfig[currentStep].map((field) => field.fieldName));
    if (isValid) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {currentStep === 1 && (
        <Grid container>
          <Stack direction="row" spacing={1}>
            {[formValues.type].map((value) => (
              <Chip label={value} key={value} size="small" />
            ))}
          </Stack>
        </Grid>
      )}
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" flexDirection="column" flex={1}>
          {formConfig[currentStep]?.map((field) => {
            const separatorConfig = getSeparatorConfig(field);
            return (
              <Fragment key={field.fieldName}>
                {separatorConfig && (
                  <Divider>
                    <Typography sx={{ color: colors.gray2 }}>
                      {separatorConfig.label.toUpperCase()}
                    </Typography>
                  </Divider>
                )}
                <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
                  <GenericFormField
                    field={field}
                    setValue={setValue}
                    trigger={trigger}
                    register={register}
                    control={control}
                    watch={watch}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
      {errors.globalError && (
        <FormHelperText error>
          {errors?.globalError?.message as string}
        </FormHelperText>
      )}
      <Grid container my={2}>
        {currentStep === 0 ? (
          <Grid item display="flex" justifyContent="flex-end" width="100%">
            <Button onClick={handleClickNext} variant="contained">
              <FormattedMessage id="common.next" />
            </Button>
          </Grid>
        ) : (
          <Grid item display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleClickPrev} variant="outlined">
              <FormattedMessage id="common.back" />
            </Button>

            <Button disabled={isLoading} type="submit" variant="contained">
              <FormattedMessage id="asset.button.add" />
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default AddAssetModal;
