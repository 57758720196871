import React from "react";
import { FormattedMessage } from "react-intl";

import ProductActions from "@components/ProductActions";
import {
  Box,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import type { ProductHeaderForm } from "@pages/ProductPage/components/ProductPageHeader/productHeaderPage.types";
import useNestedIntl from "@src/hooks/useNestedIntl";
import type {
  CategoriesDetails,
  ProductPutForm,
  SubCategoriesDetails,
} from "@src/types";
import { Authority, MODAL_ACTION_TYPES } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";
import type { RootState } from "@store/store";
import { LevelOfActivationOption, StatusOption } from "@utils/constants.utils";

interface Props extends ProductHeaderForm {
  category?: CategoriesDetails;
  subCategory?: SubCategoriesDetails;
}

const ProductAdditionalInfos: React.FC<Props> = ({
  product,
  formReturn,
  category,
  subCategory,
}) => {
  const hasUpdateProductAuthority = useAppSelector((state: RootState) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);

  const { isCancel } = product;
  const disabled = isCancel || !isSeasonOpen || !hasUpdateProductAuthority;

  const nestedIntl = useNestedIntl();

  const { register } = formReturn;

  const renderEditableField = (
    labelId: string,
    fieldName: keyof ProductPutForm,
    options: { value: string; label: string }[],
    currentValue: string,
  ) => (
    <Grid
      item
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <FormLabel>
        <FormattedMessage id={labelId} />
      </FormLabel>
      <Box>
        {!disabled ? (
          <Select
            fullWidth
            variant="standard"
            {...register(fieldName, {
              required: nestedIntl(
                { id: "common.rules.required" },
                { fieldName: labelId },
              ),
            })}
            defaultValue={currentValue}
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{currentValue}</Typography>
        )}
      </Box>
    </Grid>
  );

  return (
    <>
      <Grid
        item
        xs={3}
        display="flex"
        flexDirection="column"
        height="100%"
        gap={1}
        justifyContent="center"
      >
        <Grid item display="flex" justifyContent="space-between">
          <FormLabel>
            <FormattedMessage id="form.label.category" />
          </FormLabel>
          <Box>
            <Typography>
              {category?.name === subCategory?.name
                ? category?.name
                : `${category?.name} - ${subCategory?.name}`}
            </Typography>
          </Box>
        </Grid>

        {renderEditableField(
          "form.label.level",
          "level",
          LevelOfActivationOption,
          product.level,
        )}

        {renderEditableField(
          "form.label.status",
          "status",
          StatusOption,
          product.status,
        )}
      </Grid>

      <Grid item display="flex" alignSelf="flex-start">
        <ProductActions
          id={product.id}
          actions={[
            { action: MODAL_ACTION_TYPES.DUPLICATE_PRODUCT },
            {
              action: product.isCancel
                ? MODAL_ACTION_TYPES.UNDO_CANCEL
                : MODAL_ACTION_TYPES.CANCEL,
            },
            { action: MODAL_ACTION_TYPES.DELETE_PRODUCT },
          ]}
        />
      </Grid>
    </>
  );
};

export default ProductAdditionalInfos;
