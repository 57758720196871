import React, { type FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";
import { useDeleteAssetMutation } from "@store/api/asset";
import { useDeleteProductMutation } from "@store/api/product";
import { useAppSelector } from "@store/hooks";
import { selectProduct } from "@store/product/product.selector";

interface Props {
  onClose: () => void;
  id: number;
  isAsset?: boolean;
}

const DeleteModal: FC<Props> = ({ onClose, id, isAsset }) => {
  const navigate = useNavigate();
  const { seasonCleanName } = useAppSelector(selectProduct) || {};

  const [
    deleteAsset,
    { isLoading: isLoadingAsset, isSuccess: isSuccessAsset },
  ] = useDeleteAssetMutation();
  const [
    deleteProduct,
    { isLoading: isLoadingProduct, isSuccess: isSuccessProduct },
  ] = useDeleteProductMutation();

  useEffect(() => {
    if (isSuccessAsset || isSuccessProduct) {
      onClose();

      if (!isAsset && seasonCleanName) {
        const redirectWithParams = generatePath("/season/:cleanName", {
          cleanName: seasonCleanName,
        });
        navigate(redirectWithParams);
      }
    }
  }, [isSuccessAsset, isSuccessProduct]);

  const handleClickDelete = () => {
    if (isAsset) {
      deleteAsset({ id });
    } else {
      deleteProduct({ id });
    }
  };

  return (
    <Grid container my={2}>
      <Typography>
        <FormattedMessage id="common.delete.modal.desc.label" />
      </Typography>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        width="100%"
        mt={4}
      >
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage id="common.back" />
        </Button>

        <Button
          disabled={isLoadingAsset || isLoadingProduct}
          type="submit"
          variant="contained"
          onClick={handleClickDelete}
        >
          <FormattedMessage id="common.confirm.delete" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeleteModal;
