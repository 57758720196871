import type { ReactNode } from "react";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  StyledDeleteImageBtnContainer,
  StyledLeftIconBtnContainer,
} from "@components/Carousel/Carousel.styled";
import PreviewPopover from "@components/Preview/PreviewPopover";
import { DeleteOutline, FilePresentOutlined, Undo } from "@mui/icons-material";
import { Box, Chip, IconButton, useTheme } from "@mui/material";
import type {
  AssetFileValueResponse,
  FileComponentGetDetails,
} from "@src/types";
import { AssetContentFieldStatus } from "@src/types";
import { isImage } from "@utils/fonctions.utils";

type ICarouselImage =
  | string
  | File
  | FileComponentGetDetails
  | AssetFileValueResponse
  | (File | FileComponentGetDetails);

interface Props {
  image: ICarouselImage;
  removeImage: () => void;
  disabled?: boolean;
  disableIcon?: boolean;
  highlightImage?: boolean;
  leftIcon?: ReactNode;
}

const CarouselImage: React.FC<Props> = ({
  image,
  disabled,
  removeImage,
  disableIcon,
  highlightImage = false,
  leftIcon,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [open, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const handleMouseEnter = async () => {
    setOpenedPopover(true);
  };

  const handleMouseLeave = () => {
    setOpenedPopover(false);
  };

  const getImageUrl = (image: ICarouselImage) => {
    if (typeof image === "string") {
      return image;
    } else if ("value" in image) {
      if (image.value instanceof File) {
        return URL.createObjectURL(image.value); // asset - images that just got uploaded
      } else if ("url" in image.value) {
        return image.value.url; // asset - images from api response
      }
    } else if (image instanceof File) {
      return URL.createObjectURL(image);
    } else if ("url" in image) {
      return image.url; // images from api response
    }
  };

  const isDeletedStatus =
    (image as AssetFileValueResponse)?.status &&
    (image as AssetFileValueResponse)?.status ===
      AssetContentFieldStatus.DELETED;

  const stylesWithAssets = highlightImage &&
    (image as AssetFileValueResponse)?.status && {
      border: `2px solid ${isDeletedStatus ? theme.palette.error.main : theme.palette.success.main}`,
      borderRadius: 4,
      ...(isDeletedStatus && {
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.error.light,
          opacity: 0.4,
          zIndex: 1,
          pointerEvents: "none",
        },
      }),
    };

  const displayImageViewer = (image: ICarouselImage) => {
    if (typeof image === "object") {
      if (image instanceof File || "contentType" in image) {
        return isImage(image);
      }
    }

    return true;
  };

  const getFileName = (image: ICarouselImage) => {
    if (
      image instanceof File ||
      (typeof image === "object" && "name" in image)
    ) {
      return image.name;
    }

    return intl.formatMessage({ id: "common.defaultFile" });
  };

  if (!image) return null;

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      {leftIcon && (
        <StyledLeftIconBtnContainer>{leftIcon}</StyledLeftIconBtnContainer>
      )}

      <Box
        sx={{
          position: "relative",
          width: 160,
          height: 160,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          ...stylesWithAssets,
        }}
      >
        {displayImageViewer(image) ? (
          <>
            {!disabled && (
              <StyledDeleteImageBtnContainer>
                <IconButton onClick={removeImage} disabled={disableIcon}>
                  {(image as AssetFileValueResponse)?.status ===
                  AssetContentFieldStatus.DELETED ? (
                    <Undo />
                  ) : (
                    <DeleteOutline />
                  )}
                </IconButton>
              </StyledDeleteImageBtnContainer>
            )}
            <img
              src={getImageUrl(image)}
              ref={popoverAnchor}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: 4,
              }}
            />
            <PreviewPopover
              isOpen={open}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              anchorRef={popoverAnchor}
              keepOpenOnContent
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "40vh",
                }}
                src={getImageUrl(image)}
              />
            </PreviewPopover>
          </>
        ) : (
          <Box>
            <Chip
              sx={{
                p: 2,
                height: "auto",
                "& .MuiChip-label": {
                  width: 72,
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
              {...(!disabled && {
                onDelete: () => removeImage(),
              })}
              label={getFileName(image)}
              icon={<FilePresentOutlined />}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CarouselImage;
