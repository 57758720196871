import React, { useState } from "react";
import { useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import AssortmentTab from "@pages/ProductPage/components/ProductPageHeader/components/AssortmentTab";
import OwnersTab from "@pages/ProductPage/components/ProductPageHeader/components/OwnersTab";
import type { ProductHeaderForm } from "@pages/ProductPage/components/ProductPageHeader/productHeaderPage.types";
import type { ProductPutForm } from "@src/types";
import { Authority, FIELD_TYPES } from "@src/types";
import { useDeleteAdditionalPhotosProductMutation } from "@store/api/product";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import {
  selectAreWeBeforeNoDefDate,
  selectIsSeasonOpen,
} from "@store/season/season.selector";
import type { RootState } from "@store/store";

const ProductPageHeaderTabs: React.FC<ProductHeaderForm> = ({
  product,
  formReturn,
  productFieldsConfig,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const intl = useIntl();
  const [deleteAdditionalPhotos] = useDeleteAdditionalPhotosProductMutation();
  const { register, setValue, watch, control, trigger } = formReturn;
  const hasUpdateProductAuthority = useAppSelector((state: RootState) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const hasMarketingProductUpdateProductAuthority = useAppSelector(
    (state: RootState) =>
      selectUserHasAuthority(
        state,
        Authority.UPDATE_PRODUCT_MARKETING_PRODUCT_TEAM,
      ),
  );

  const isAssortmentFileHighlighted = !useAppSelector(
    selectAreWeBeforeNoDefDate,
  );

  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);

  const isDisabled = (fieldName: keyof ProductPutForm) => {
    const defaultDisabled = !isSeasonOpen || product.isCancel;
    switch (fieldName) {
      case "elementToHighlight":
      case "productOwner":
        return (
          defaultDisabled ||
          !(
            hasUpdateProductAuthority ||
            hasMarketingProductUpdateProductAuthority
          )
        );
      default:
        return defaultDisabled || !hasUpdateProductAuthority;
    }
  };

  const formValues = watch();

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const handleDeleteAdditionalPhoto = (index: number) => {
    if (formValues?.additionalPhotos?.length) {
      deleteAdditionalPhotos({
        name: formValues.additionalPhotos[index].name,
        productId: product.id,
      });
      const updatedPhotos = [...formValues.additionalPhotos];
      updatedPhotos.splice(index, 1);
      setValue("additionalPhotos", updatedPhotos, { shouldDirty: true });
    }
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <Grid item>
            <GenericFormField<ProductPutForm>
              field={{
                fieldName: "elementToHighlight",
                fieldType: FIELD_TYPES.RICH_TEXT,
                mandatory: productFieldsConfig.elementToHighlight.mandatory,
                disabled: isDisabled("elementToHighlight"),
                maxHeight: 150,
              }}
              setValue={setValue}
              trigger={trigger}
              register={register}
              control={control}
              watch={watch}
            />
          </Grid>
        );

      case 1:
        return (
          <AssortmentTab
            productFieldsConfig={productFieldsConfig}
            setValue={setValue}
            formValues={formValues}
            isAssortmentFileHighlighted={isAssortmentFileHighlighted}
            hasUpdateProductAuthority={hasUpdateProductAuthority}
            isDisabled={isDisabled}
            handleDeleteAdditionalPhoto={handleDeleteAdditionalPhoto}
          />
        );
      case 2:
        return (
          <OwnersTab
            productFieldsConfig={productFieldsConfig}
            product={product}
            setValue={setValue}
            trigger={trigger}
            register={register}
            control={control}
            watch={watch}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return (
    <>
      <Grid container sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          {[
            intl.formatMessage({ id: "product.header.tab.marketing" }),
            intl.formatMessage({ id: "product.header.tab.assortment" }),
            intl.formatMessage({ id: "product.header.tab.owners" }),
          ].map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </Grid>
      <Box width="100%" sx={{ p: 1 }}>
        {renderTabContent()}
      </Box>
    </>
  );
};

export default ProductPageHeaderTabs;
